.field-section {
  font-size: 1.5em;
  font-weight: 500;
}

.field2-section {
  color: gray;
  font-size: 1.3em;
  font-weight: 400;
}

/* ___ return button ___ */
.back_button {
  display: flex;
  margin: 1em 1em 4em 1em;
  font-family: sans-serif;
  font-size: 1.1em;
  color: #171A1FFF;
  align-items: center;
}

/* ___ checklist ___ */
ul.ds-select-list > * {
  display: flex;
  padding-right: 3em;
  margin-left: 2rem;
  align-items: center;
}
.ds-select-list > * > label {
  margin-bottom: 0;
  margin-left: 0.5em;
}
.ds-select-list > * > input {
  font-size: 1.3em;
  margin-top: 0;
}
.ds-select-list > * > input:checked {
  background-color:#48ABF6FF;
  border: none;
}


/* ___ appliquer filtres ___ */
.ds-btn-confirmation {
  display: flex;
  font-weight: bold;
  /*font-family: Consolas;*/
  /*font-size: 1.3em;*/
  vertical-align: center;
  align-self: center;
  /*margin: 3em auto auto;*/
  background-color: rgb(0, 189, 214);
  color: #f0f2f3;
  /*font-weight: 600;*/
  border: 0;
  padding: 0.5em 1.5em;
  margin: 0 auto;
}

.ds-btn-continuation {
  display: flex;
  font-weight: bold;
  /*font-family: Consolas;*/
  /*font-size: 1.3em;*/
  vertical-align: center;
  align-self: center;
  /*margin: 3em auto auto;*/
  background-color: #00BDD6;
  color: #f0f2f3;
  /*font-weight: 600;*/
  border: 0;
  padding: 0.5em 1.5em;
  margin: 0 auto;
}

.ds-btn-confirmation-container {
  display: flex;
  justify-content: space-around;
  font-weight: 600;
  margin: 0/*3em*/ auto;
}


.ds-btn-annulation{
  display: flex;
  font-weight: bold;
  vertical-align: center;
  align-self: center;
  background-color: #DC434380;
  color: #f0f2f3;
  border: 0;
  padding: 0.5em 1.5em;
  margin: 0 auto;
}

/* ____ refresh filters ___ */
.refresh-filter {
  font-size: 1em;
  padding: 0 0.5em;
  height: 2rem;
  background-color: transparent;
  border: none;
  color: #1076c5;
  text-decoration: underline;
  cursor: pointer;
}

/* ____ auto filters ___ */
.auto-filter {
  border: 2px #0b2e13 !important;
}

.btn-outline-success.auto-filter:focus,
.btn-outline-success.auto-filter:active {
  background-color: #fff !important;
  border-color: #1076c5 !important;
  color: #1076c5 !important;
}

/*multi-chooser with minilist*/
.app-list-button, .app-list-button.active  {
  font-size: 85%;
  margin: 0.2em 0.4em;
  border-radius: 4px;
  border-width: 0;
  padding: 0.5em;
  min-width: 6em;
  max-height: 40px;
  background-color: #f3f3f3;
}
.app-list-button.active {
  background-color: #48ABF6FF;
}
.app-list-button:hover {
  background-color: #a0cbfc;
}

/* multi-chooser with minilist about Lieux*/
.lieux-list {
  margin: 1em 1em;
  border-radius:8px;
  padding: 0.4em 0;
  width: fit-content;
}

.lieux-list > a {
  margin: 0.2em 0.8em;
  /*margin: 5px;*/
  max-width: 2em;
  border-radius: 3px;
  border: 0;
}

.section-separator {
  border: rgb(120, 120, 120) 01px solid;
  width: 75%;
  margin: 2.5em auto;
}

/* COMPONNENT CLASSIC MESSAGE */
.ds-message, .ds-message-error {
  display: block;
  text-align: center;
  font-size: 1em;
}

.ds-message-error {
  color: red;
  font-weight: bold;
}

/* POUR GÉRER LE STYLE DU TOAST NOTIF PUSH*/
.app-neutre-toast {
  background-color: gray;
}

.toast-container {
  width: 100% !important;
  position: fixed !important;
}
