/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/css/all.css';

/*@import 'bootstrap/dist/css/bootstrap.css';*/
/*@import 'bootstrap/dist/css/bootstrap-utilities.css';*/
/*@import 'mdb-ui-kit/css/mdb.min.css';*/
@import "app/components/common/multi-select/multi-select.css";
@import 'open-iconic/font/css/open-iconic-bootstrap.css';
@import "node_modules/ngx-toastr/toastr.css";
@import "src/app/components/common/styles/common.css";

/*scrollbar*/
/*::-webkit-scrollbar {*/
/*  width: 12px;*/
/*}*/

/*::-webkit-scrollbar-track {*/
/*  -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);*/
/*  border-radius: 10px;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  border-radius: 10px;*/
/*  background-color: #a4a4a4;*/

/*}*/

/*::-webkit-scrollbar-thumb:hover {*/
/*  border-radius: 10px;*/
/*  background-color: #5b5b5b;*/

/*}*/

/*button {*/
/*  background-color: #a4a4a4;*/
/*}*/

.background_home {
  background-image: url("src/assets/images/background_home.png");
  background-repeat: repeat-y;
  background-size: cover;
}

 /*scroll to top*/
html {
  scroll-behavior: auto !important;
}

.to-top {
  background: #289cd3;
  position: fixed;
  bottom: 16px;
  right:32px;
  width:50px;
  height:50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:32px;
  color: #ffffff !important;
  text-decoration: none;
  opacity:0;
  pointer-events: none;
  transition: all .4s;
  z-index: 5;
}

.to-top.active {
  bottom:32px;
  pointer-events: auto;
  opacity:1;
}

/* age selector width */
.slider-horizontal {
  width: calc(100% - 100px) !important;
  height: 20px !important;
}

.blue {
  color: #289cd3;
}

.green {
  color: #1e7e34;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.button_disabled
{
  background-color: #93C83F;
}

/* Angular 15 adds grey background on mat form components */
/* below removes that background color */
/* Manipulate initial background color*/
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
/*Manipulate background color on hover and focus*/
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.wrap-word {
  white-space: normal;
  overflow-wrap: break-word;
}

